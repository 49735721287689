<template>
  <div>
    <v-card style="width: 100vw; overflow-x: hidden;" class="rounded-xl mt-5">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
          color: ${template.colors.inline_primary_text};
        `"
      >
        <v-toolbar-title>
          {{ template.var.verband == 'DEU' ? 'Gruppe' : 'Disziplin' }}
          bearbeiten
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          :color="template.colors.inline_primary_text"
          @click="closing()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center" class="mt-4">
          <v-col cols="10" lg="8" class="px-2">
            <v-text-field
              v-model="disziplin.name"
              :rules="[rules.required]"
              filled
              rounded
              :label="
                template.var.verband == 'DEU'
                  ? 'Name der Gruppe'
                  : 'Name der Disziplin'
              "
            ></v-text-field>
            <v-select
              v-model="disziplin.sportstaette"
              v-if="user.data.sportstaetten"
              :rules="[rules.required]"
              :items="user.data.sportstaetten"
              item-text="name"
              item-value="name"
              filled
              rounded
              label="Sportstätte"
            ></v-select>
            <v-text-field
              v-model="disziplin.importname"
              :rules="[rules.required, rules.importname]"
              filled
              rounded
              label="Importname"
              hint="Dieser Name muss in CSV-Importen verwendet werden"
            ></v-text-field>
            <v-dialog
              ref="beginndatum"
              v-model="beginndatum"
              :return-value.sync="disziplin.beginn.datum"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="parse_datum(disziplin.beginn.datum)"
                  label="Datum"
                  :rules="[rules.required]"
                  filled
                  rounded
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="de"
                first-day-of-week="1"
                v-model="disziplin.beginn.datum"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="beginndatum = false"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="
                    $refs.beginndatum.save(disziplin.beginn.datum)
                    disziplin.ende.datum = disziplin.beginn.datum
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              class="mb-4"
              ref="beginnuhrzeit"
              v-model="beginnuhrzeit"
              :return-value.sync="disziplin.beginn.uhrzeit"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="disziplin.beginn.uhrzeit"
                  :rules="[rules.required]"
                  label="Beginn"
                  filled
                  rounded
                  prepend-icon="mdi-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                format="24hr"
                v-model="disziplin.beginn.uhrzeit"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="beginnuhrzeit = false"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="$refs.beginnuhrzeit.save(disziplin.beginn.uhrzeit)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
            <v-dialog
              class="mb-4"
              ref="endeuhrzeit"
              v-model="endeuhrzeit"
              :return-value.sync="disziplin.ende.uhrzeit"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="disziplin.ende.uhrzeit"
                  label="Ende"
                  :rules="[rules.required]"
                  filled
                  rounded
                  prepend-icon="mdi-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                format="24hr"
                v-model="disziplin.ende.uhrzeit"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="endeuhrzeit = false"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="$refs.endeuhrzeit.save(disziplin.ende.uhrzeit)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
            <v-divider></v-divider>
            <v-checkbox
              v-model="disziplin.public"
              :label="`Linkregistrierung ${
                disziplin.public ? 'aktiviert' : 'deaktiviert'
              }`"
            ></v-checkbox>
            <v-divider></v-divider>
            <v-btn
              class="my-4"
              block
              rounded
              large
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              :disabled="!check_valid()"
              @click="save()"
              :loading="creating"
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>
              Speichern
            </v-btn>
            <v-btn
              class="my-4"
              block
              rounded
              large
              outlined
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              @click="closing()"
            >
              Abbrechen
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-btn
      class="my-5"
      rounded
      large
      block
      :color="template.colors.primary"
      @click="delete_disziplin ? deletedisziplin() : (delete_disziplin = true)"
    >
      <v-icon class="mr-2">mdi-delete</v-icon>
      {{ !delete_disziplin ? 'Disziplin löschen' : 'Löschen bestätigen' }}
    </v-btn>
    <v-btn
      class="my-5"
      rounded
      outlined
      large
      block
      :color="template.colors.primary"
      v-if="delete_disziplin"
      @click="delete_disziplin = false"
    >
      Abbruch
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from 'firebase'
import store from '../../../../../store'
import router from '../../../../../routes/index'

export default {
  name: 'Veranstaltung',
  data() {
    return {
      anz_teilnehmer: 0,
      zuschauerinfo: false,
      veranstaltungedit: false,
      editable: true,
      sportstaette: '',
      disziplin: '',
      beginndatum: false,
      beginnuhrzeit: false,
      endedatum: false,
      creating: false,
      bisdatum: false,
      valid: false,
      vondatum: false,
      endeuhrzeit: false,
      delete_disziplin: false,
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        importname: (v) =>
          v.match(/(@+)|(,+)|(:+)|\s/)
            ? 'Der Importname enthält unzulässige Zeichen'
            : true,
      },
      counter: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.data.uid)
      .collection('Veranstaltung')
      .doc(this.$route.params.veranstaltung)
      .collection('Disziplin')
      .doc(this.$route.params.disziplin)
      .get()
      .then((doc) => {
        this.disziplin = doc.data()
        this.disziplin.id = doc.id
      })
  },
  methods: {
    closing() {
      router.push(
        '/wettkampf/' +
          this.veranstaltung.id +
          '/disziplinen/' +
          this.disziplin.id,
      )
    },
    parse_datum(datum) {
      if (datum) {
        var d = datum.split('-')
        return d[2] + '.' + d[1] + '.' + d[0]
      } else {
        return datum
      }
    },
    addNull(val) {
      if (val >= 10) {
        return val
      } else {
        return '0' + val
      }
    },
    check_valid() {
      if (
        this.disziplin.name &&
        this.disziplin.beginn.datum &&
        this.disziplin.beginn.uhrzeit &&
        this.disziplin.ende.uhrzeit
      ) {
        return true
      } else {
        return false
      }
    },
    async save() {
      if (this.check_valid()) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Veranstaltung')
          .doc(this.veranstaltung.id)
          .collection('Disziplin')
          .doc(this.disziplin.id)
          .update(this.disziplin)
          .then((docRef) => {
            this.closing()
          })
      }
    },
    async deletedisziplin() {
      if (this.delete_disziplin) {
        firebase
          .firestore()
          .doc(
            'User/' +
              this.user.data.uid +
              '/Veranstaltung/' +
              this.veranstaltung.id +
              '/Disziplin/' +
              this.disziplin.id,
          )
          .delete()
          .then(() => {
            router.push('/wettkampf/' + this.veranstaltung.id + '/disziplinen')
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
  },
}
</script>
